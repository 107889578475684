<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      @history="onHistory"
      @collapse="onCollapse"
      :status="status"
      :name="name"
      autosave
    ></FieldHeader>
    <ChildAssessmentTable
      v-if="!disabled && !hideBody"
      :value.sync="data"
    ></ChildAssessmentTable>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      @copy="onCopy"
    >
      <ChildAssessmentTable
        :value="data"
        class="my-2"
        disabled
      ></ChildAssessmentTable>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    data: {
      get() {
        if (!this.field.data) {
          return {
            gross_motor_skills: "",
            fine_motor_skills: "",
            communication: "",
            cognitive_area: "",
            social_emotional_area: "",
          };
        } else {
          return this.field.data;
        }
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
  },
  components: {
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    ChildAssessmentTable: () =>
      import("@/components/tables/patientcard/ChildAssessmentTable"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("dataJson");
    }
  },
};
</script>